import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import Title1 from '@/components/title1/title1.vue';
import ShowPath from '@/components/showPath/showPath.vue';
import LicitacoesList from '@/modules/licitacoes/components/licitacoesList/licitacoesList.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    Layout,
    Title1,
    ShowPath,
    LicitacoesList
  }
})
export default class Licitacoes extends Vue {

}
  